<template>
  <transition name="fade">
    <div class="preloader">
      <div class="preloader__bg">
        <img class="preloader__gradient first" src="@/assets/img/preloader/gradient_1.svg" alt="">
        <img class="preloader__gradient second" src="@/assets/img/preloader/gradient_2.svg" alt="">
      </div>
      <Lottie
        :options="defaultOptions"
        v-on:animCreated="handleAnimation"
      />

      <div class="preloader__progress">
        <div class="preloader__progress--line">
          <div class="preloader__progress--num-container">
            <div class="preloader__progress--num"></div>
            <span>%</span>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Lottie from 'vue-lottie';
import animationData from '@/assets/style/animation/preloader.json';
import {mapActions} from "vuex";

export default {
  name: 'Preloader',
  components: {
    Lottie,
  },
  data() {
    return {
      percent: 1,
      defaultOptions: {
        animationData,
        loop: false,
        autoplay: true,
      },
    };
  },
  watch: {
    percent(newVal, oldVal) {
      if (newVal === 100) {
        this.setPreloader();
      }
    },

  },
  methods: {
    ...mapActions(['setPreloader']),
    handleAnimation(anim) {
      this.anim = anim;
    },
    animateNum() {
      const progressNum = document.querySelector('.preloader__progress--num');
      setInterval(() => {
        if (this.percent !== 100) {
          this.percent += 1;
          progressNum.textContent = this.percent;
        } else {
        }
      }, 40);
    },
  },
  mounted() {
    // eslint-disable-next-line no-plusplus
    this.animateNum()
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/animation.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.preloader {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  //transition: all 2s ease;
  z-index: 9999999999;

  &__gradient {
    position: absolute;

    &.first {
      left: -20%;
      top: -100%;
      bottom: auto;
      max-width: 70%;
      object-fit: contain;
      animation: rotateGradient1 10s infinite linear;
    }

    &.second {
      right: -20%;
      bottom: -60%;
      max-width: 90%;
      object-fit: contain;
      animation: rotateGradient2 10s infinite linear;
    }
  }

  &__logo {
    position: relative;
    top: 48%;
    z-index: 1;

    display: flex;
    width: 90%;
    justify-content: space-around;
    margin: 0 auto;
    animation: widthText 3s ease-out;
    animation-fill-mode: forwards;
    animation-delay: 2s;

    .before-end {
      display: none;
      animation: addText 1s ease-out;
    }

    .end {
      display: none;
      animation: addText 1s ease-out;
    }

    .letter {
      opacity: 0;
      animation: addText 1s ease-out;
      animation-fill-mode: forwards;
      white-space: nowrap;

      &:first-child {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        animation-delay: .8s;
      }

      &:nth-child(4) {
        animation-delay: 1s;
      }

      &:nth-child(5) {
        animation-delay: 1.2s;
      }

      &:nth-child(6) {
        animation-delay: 1.4s;
      }

      &:nth-child(7) {
        animation-delay: 1.6s;
      }

      &:nth-child(8) {
        animation-delay: 1.8s;
      }
    }
  }

  &__progress {
    width: 80%;
    height: 1px;
    background: #C6D1DA;

    position: absolute;
    bottom: 138px;
    z-index: 3;

    margin-left: 10%;
    margin-right: 10%;

    &--line {
      position: absolute;
      height: 2px;
      width: 0;
      background-color: #92A1AE;
      animation: widthProgress 3.4s cubic-bezier(.82, .47, 1, .76);
      animation-fill-mode: forwards;
    }

    &--num-container {
      position: absolute;
      right: -32px;
      left: auto;
      bottom: 10px;
      display: flex;

      span {
        font-family: "TimesNewRoman-I";
        font-size: 36px;
        font-style: italic;
        align-self: flex-end;
        color: #92A1AE;
      }
    }

    &--num {
      font-family: "TimesNewRoman-I";
      font-size: 48px;
      font-style: italic;
      color: #92A1AE;
    }
  }
}

@media screen and (max-width: 920px) {
  .preloader {
    &__gradient {
      &.first {
        max-width: 100%;
        top: -30%;
      }

      &.second {
        max-width: 100%;
        bottom: -20%;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .preloader {
    &__logo {
      max-height: 16px;

      .letter {
        max-width: 12px;
      }
    }

    &__progress {
      &--num {
        font-size: 36px;

        &-container > span {
          font-size: 28px;
        }
      }
    }
  }
}

</style>
